<template>
  <div>
    <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <v-dialog v-model="openDialog" width="500">
      <v-card>
        <v-card-title class="headline red white--text" primary-title>
          <v-spacer />Atenção
          <v-spacer />
        </v-card-title>
        <v-card-text class="pa-5">Deseja sair, todo o processo será encerrado</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn text @click="openDialog = false">Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn text to="/pages/covenants">Sim</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- =======================================================
                                Snackbar
    ============================================================-->
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.active = false">Cancelar</v-btn>
      </template>
    </v-snackbar>

    <!-- =======================================================
                                Main
    ============================================================-->
    <v-container id="createEditcovenants" fluid tag="section">
      <v-row justify="center">
        <v-col cols="10">
          <v-stepper v-model="stepper">
            <v-stepper-header>
              <v-divider></v-divider>
              <v-stepper-step :complete="stepperIndex > 1" step="1">Dados</v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step :complete="stepperIndex > 2" step="2">Post</v-stepper-step>

              <v-divider></v-divider>
            </v-stepper-header>

            <v-stepper-items class="stepper-items">
              <v-stepper-content step="1">
                <v-row justify="space-around">
                  <v-col cols="5">
                    <v-form>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12">
                            <v-col cols="12">
                              <v-text-field
                                v-model="title"
                                label="Titulo da convênio"
                                outlined
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="category"
                                outlined
                                :items="categoryes"
                                :rules="[v => !!v || 'Escolha uma categoria']"
                                label="Categoria"
                                required
                              ></v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-menu
                                v-model="menuPicker"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="computedDateFormatted"
                                    label="Data da postagem"
                                    persistent-hint
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @blur="date = parseDate(dateFormatted)"
                                    outlined
                                    required
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="date"
                                  locale="pt-br"
                                  no-title
                                  @input="menuPicker = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="d-flex align-center justify-center" cols="5">
                    <v-row>
                      <v-col>
                        <card
                          :title="title"
                          :url="fileImgCardPreview"
                          :file="imgCard"
                          :category="categoryComputed"
                          :height="238"
                          @fileUrl="fileUrl"
                        />

                        <v-col class="d-flex align-center justify-center" cols="12">
                          <input
                            v-show="false"
                            accept="image/*"
                            ref="inputUpload"
                            type="file"
                            @change="onFileChange"
                          />
                          <v-btn
                            color="success"
                            @click="$refs.inputUpload.click()"
                          >{{imgCard || fileImgCardPreview? 'Trocar imagem':'Escolha uma imagem'}}</v-btn>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-container cols="12" class="d-flex justify-space-between px-0">
                  <v-btn text @click.prevent="cancel()">Cancelar</v-btn>
                  <v-btn color="primary" @click="next()">Próximo</v-btn>
                </v-container>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-row>
                  <v-col cols="12">
                    <v-container cols="12" class="d-flex justify-center">
                      <template>
                        <div class="editor-wrapper">
                          <vue-editor
                            useCustomImageHandler
                            v-model="content"
                            @imageAdded="handleImageAdded"
                          ></vue-editor>
                        </div>
                      </template>
                    </v-container>
                  </v-col>
                </v-row>

                <v-container cols="12" class="d-flex justify-space-between px-0">
                  <v-btn text @click="stepper = 1">Voltar</v-btn>
                  <v-btn color="primary" @click="create()">Salvar</v-btn>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import categoryService from "../../../../service/categoryService";
import covenantsService from "../../../../service/covenantsService";
import { VueEditor, Quill } from "vue2-editor";

export default {
  name: "CreateEditcovenants",
  components: {
    Card: () => import("../../../../components/commons/Card"),
    VueEditor
  },
  data: vm => ({
    title: "",
    category: null,
    imgCard: "",
    imgCardoOld: "",
    menuPicker: false,
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    fileImgCardPreview: "",
    overlay: false,
    content: "",
    allUrlPost: [],
    openDialog: false,
    stepper: 1,
    stepperIndex: 1,
    categoryes: [],
    snackbar: {
      text: "",
      active: false
    },
  }),
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${day.padStart(2, "0")}-${month.padStart(2, "0")}`;
    },
    fileUrl(url) {
      this.fileImgCardPreview = url;
    },
    cancel() {
      this.title || this.category || this.imgCard
        ? (this.openDialog = true)
        : this.$router.push({ path: "/pages/covenants" });
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("image", file);
      this.overlay = true;
      covenantsService.uploadFile(formData).then(result => {
          let url = result.data;
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();

          this.overlay = false;
        })
        .catch(err => {});
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.imgCard = files[0];
    },
    getCategorys() {
      this.overlay = true;
      categoryService.get({}).then(res => {
        this.categoryes = res.data.map(category => {
          category.value = category.id;
          category.text = category.name;
          this.overlay = false;
          return category;
        });
      });
    },
    create() {
      const formData = new FormData();

          if(this.imgCard){
            formData.append("image", this.imgCard, `card_${new Date().getTime()}`);
          }

          formData.append("title", this.title);
          formData.append("id_category", this.category);
          formData.append("content", this.formatPost(this.content));
          formData.append("date", this.formatDate(this.date));

      if (this.id) {
          this.overlay = true;  
          covenantsService.update(this.id, formData).then(res => {
              this.overlay = false;
              this.$router.push({ path: "/pages/covenants" });
          }).catch(error => {
              this.overlay = false;
              this.snackbar.text = "Erro ao salvar a noticia.";
              this.snackbar.active = true;
          });
      } else {
          this.overlay = true;
          covenantsService.create(formData).then(res => {
              this.overlay = false;
              this.$router.push({ path: "/pages/covenants" });
          }).catch(error => {
              this.overlay = false;
              this.snackbar.text = "Erro ao salvar a noticia.";
              this.snackbar.active = true;
          });;
      }
    },
    next() {
      if (!this.title) {
        this.snackbar.text = "O titulo não pode ser vazio!";
        this.snackbar.active = true;
      } else if (!this.category) {
        this.snackbar.text = "A categoria não pode ser vazia!";
        this.snackbar.active = true;
      } else if (!this.imgCard && !this.fileImgCardPreview) {
        this.snackbar.text = "A foto do card não pode ser vazia";
        this.snackbar.active = true;
      } else {
        this.snackbar.text = "";
        this.snackbar.active = false;
        this.stepper = 2;
      }
    },
    formatPost(content) {
      let images = [];
      let regex = /<img src="([\w\W]+?)"/g;

      images = content.match(regex);

      if (images) {
        images.forEach(img => {
          content = String(content).replace(
            '<img src="',
            '<img class="img-post" src="'
          );
        });
      }

      return content;
    }
  },
  computed: {
    categoryComputed() {
      const categoryes = this.categoryes.filter(
        res => res.id === this.category
      );
      if (categoryes && categoryes.length > 0) {
        return categoryes[0].text;
      }
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    editorContent: {
      get() {
        return this.formatPost(this.content);
      },
      set(updatedContent) {
        this.$emit("update:content", this.formatPost(updatedContent));
      }
    }
  },
  mounted() {
    this.id = parseInt(this.$router.history.current.params.id);

    this.getCategorys();
    if (this.id) {
      this.overlay = true;
      covenantsService.get({ id: this.id }).then(res => {
        const data = res.data[0];
        this.title = data.title;
        this.category = data.id_category;
        this.date = this.parseDate(data.date)
        this.fileImgCardPreview = data.path_img_card;
        this.content = data.content;
        this.overlay = false;
      });
    }
  }
};
</script>


<style lang="sass">
.editor
  max-width: 100%
  width: 100%
  max-height: 28rem
  min-height: 28rem

.img-post
  max-width: 100%
  background-position: center

.ql-editor
  max-height: 360px !important
  height: 360px !important
  overflow: auto !important

.card-img
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  height: 100%
  width: 100%

</style>